import { SessionStatus, SESSION_ACTION } from '../actions'
import { getAccessToken } from '../../auth/AuthService'

const initialState = {
    sessionStatus: getAccessToken() ? SessionStatus.LOGGED_IN : SessionStatus.NOT_LOGGED_IN
}

export default function session(state = initialState, action) {
    switch (action.type) {
        case SESSION_ACTION:
            return Object.assign({}, state, {
                sessionStatus: action.sessionStatus,
                username: action.username,
                loginError: action.loginError
            })
        default:
            return state
    }
}
