import React, { Component } from "react";
import "./Blog.css";
import DateBadge from "../components/DateBadge";
import { Container, Row, Col } from 'reactstrap';
import Parser from 'html-react-parser';

class BlogPost extends Component {
	constructor(props) {
		super(props);

		let startDate = new Date(props.event.published);
		let startYear = startDate.getYear();
		let startMonth = (1 + startDate.getMonth()).toString().padStart(2, "0");
		let startDayOfMonth = (startDate.getDate()).toString().padStart(2, "0");

		// TODO display times properly in the link ('T' + HHMMSS)
		let startTime = 'T';

		let start = startYear + '' + startMonth + '' + startDayOfMonth + startTime;

		let endDate = new Date(props.event.published);
		let endYear = endDate.getYear();
		let endMonth = (1 + endDate.getMonth()).toString().padStart(2, "0");
		let endDayOfMonth = (endDate.getDate()).toString().padStart(2, "0");

		// TODO display times properly in the link ('T' + HHMMSS)
		let endTime = 'T';

		let end = endYear + '' + endMonth + '' + endDayOfMonth + endTime;

		this.state = {
			summary: props.event.title,
			description: props.event.content,
			start: { dateTime: startDate },
			postUrl: props.event.url,
			isOldPost: props.isOldPost
		}
	}

	render() {
		let blogPostClass = this.state.isOldPost ? "site-text blog-old-post-summary" : "site-text blog-post-summary";
		let buttonText = this.state.isOldPost ? "Read more" : "View post";
		return (
			<Container className={this.props.rowClassName}>
                <Row>
					<Col xs="3" sm="3">
						<div className="date-badge">
							<DateBadge startDate={this.state.start} attachments={this.state.attachments}/>
						</div>
					</Col>
                    <Col xs="9" sm="5">
						<div className="event-badge">
							<div className="event-title" style={{ fontFamily: "SpecialElite" }}>{this.state.summary}</div>
							<div className={blogPostClass}>{this.state.description ? Parser(this.state.description) : ""}</div>
							<div>
								<a className="site-text" href={this.state.postUrl} target="_blank" rel="noopener noreferrer">{buttonText}</a>
							</div>
						</div>
                    </Col>
					<Col xs="12" sm="4">
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default BlogPost;
