import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './Shopify.css';

class BuyNow extends Component {
	render() {
		return (
            <div className="has-image shopify-buy__layout-vertical shopify-buy__product" style={{ paddingBottom: '12px' }}>
                <div className="shopify-buy__product-img-wrapper" data-element="product.imgWrapper">
                    <img alt={this.props.productTitle} data-element="product.img" class="shopify-buy__product__variant-img" src={this.props.imageSrc}/>
                </div>
                <h1 className="shopify-buy__product__title" data-element="product.title">{this.props.productTitle}</h1>
                <div className="shopify-buy__btn-wrapper" data-element="product.buttonWrapper" style={{ "textAlign": "center" }}>
					{
						(this.props.disabled) &&
						<Button disabled color="inherit" target="_blank" classes={{ label: 'shopify-buy__btn btn-disabled' }} style={{ "padding": "0" }}>{this.props.buttonTitle}</Button>
					}
					{
						(!this.props.disabled) &&
						<Button target="_blank" href={this.props.buttonLink} classes={{ label: 'shopify-buy__btn btn-enabled' }} style={{ "padding": "0" }}>{this.props.buttonTitle}</Button>
					}
                </div>
            </div>
		);
	}
}

/*const mapStateToProps = (state, ownProps) => {
	return {
		active: ownProps.blogs === state.blogs
	}
}

export default connect(mapStateToProps)(BuyNow);*/

export default BuyNow;
