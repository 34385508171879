import React, { Component } from 'react';
import './App.css';
import About from "./About";
import { createStyle } from "./styling";

import EcwidCatalog from "./Ecwid";
import ShopifyBuyNow from "./ShopifyBuyNow";
import Product1 from "./Product1";
import Product6 from "./Product6";
import ShopifyProducts from "./ShopifyProducts";
import Cart from './components/shopify/Cart';
import { handleCartClose, handleCartOpen } from './redux/actions/cartActions';
import { handleAlertClose } from './redux/actions/alertActions';

import Events from "./Events";
import Submissions from "./Submissions";
import ContactPage from "./ContactPage";

import { Helmet } from "react-helmet";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Route, NavLink  } from "react-router-dom";

import { SessionStatus } from './redux/actions'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faPhone, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';

class App extends Component {
	constructor(props) {
		super(props);
		this.handleCartClose = this.props.handleCartClose.bind(this);
        this.handleCartOpen = this.props.handleCartOpen.bind(this);
		this.handleAlertClose = this.props.handleAlertClose.bind(this);
		this.state = {
		};
	}

	toggleDrawer(anchor, open) {
		this.setState(state => ({
			[anchor]: open
		}));
    };

	// The background-color contained here sets the background for the entire app!
	render() {
		return (
			<div className="App">

				<Helmet>
					<style>{'body { background-color: #fafafa; }'}</style>
					<title>Newfangle Press</title>
					<meta name="google-site-verification" content="NGCtSxuSzrQED1Pm4oA36ZQnW5Q4U_5Cqidht9CYiB4"/>

					<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
					<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
					<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
					<link rel="manifest" href="/site.webmanifest"/>
					<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
					<meta name="msapplication-TileColor" content="#da532c"/>
					<meta name="theme-color" content="#ffffff"/>
				</Helmet>

				<Backdrop style={{ "color": "#a22f7d", "zIndex": "1200" }} open={this.props.isProgressOpen}>
                    <CircularProgress color="inherit" />
                </Backdrop>

				<div className="only-mobile">
					<AppBar position="static" style={{ background: '#f1f3f4' }}  elevation={0}>
						<Toolbar>
							<IconButton onClick={this.toggleDrawer.bind(this, 'navMenu', true)} edge="start" className="menuButton" color="#585A5C" aria-label="menu">
								<MenuIcon className="only-mobile"/>
							</IconButton>
							<Drawer anchor='left' open={this.state['navMenu']} onClose={this.toggleDrawer.bind(this, 'navMenu', false)}>
								<List>
									<NavLink className="nav-item" to={{ pathname: '/', state: 'refreshStore' }}>
										<ListItem button key="home">
											<ListItemText disableTopography="true">
												<Typography variant="h6" className="title.dark" style={createStyle('navTypography')}>Home</Typography>
											</ListItemText>
										</ListItem>
									</NavLink>
									<NavLink className="nav-item" to={{ pathname: '/catalog', state: 'refreshStore' }}>
										<ListItem button key="books">
											<ListItemText disableTopography="true">
												<Typography variant="h6" className="title.dark" style={createStyle('navTypography')}>Catalog</Typography>
											</ListItemText>
										</ListItem>
									</NavLink>
									<NavLink className="nav-item" to={{ pathname: '/submissions', state: 'refreshStore' }}>
										<ListItem button key="submissions">
											<ListItemText disableTopography="true">
												<Typography variant="h6" className="title.dark" style={createStyle('navTypography')}>Submissions</Typography>
											</ListItemText>
										</ListItem>
									</NavLink>
									<NavLink className="nav-item" to={{ pathname: '/events', state: 'refreshStore' }}>
										<ListItem button key="events">
											<ListItemText disableTopography="true">
												<Typography variant="h6" className="title.dark" style={createStyle('navTypography')}>Events</Typography>
											</ListItemText>
										</ListItem>
									</NavLink>
									<NavLink className="nav-item" to={{ pathname: '/contact', state: 'refreshStore' }}>
										<ListItem button key="contact">
											<ListItemText disableTopography="true">
												<Typography variant="h6" className="title.dark" style={createStyle('navTypography')}>Contact</Typography>
											</ListItemText>
										</ListItem>
									</NavLink>
								</List>
							</Drawer>

							<NavLink className="nav-item" to="/">
							<div style={{ "flexGrow": "1" }}>
								<img src={require('./logo/NP_logo_A_bloody.png')} width="265" alt="Newfangle Press"/>
							</div>
							</NavLink>

							<IconButton color="inherit" onClick={this.handleCartOpen} style={{ display: "none" }}>
								<FontAwesomeIcon icon={faShoppingCart} size="xs" color="#585A5C" className="title"/>
							</IconButton>
							<Drawer anchor='right' open={this.props.isCartOpen} onClose={this.handleCartClose}>
								<Cart
			                    	checkout={this.props.checkout}
			                    	isCartOpen={this.props.isCartOpen}
			                    	handleCartClose={this.handleCartClose}
			                    	updateQuantityInCart={this.updateQuantityInCart}
			                    	removeLineItemInCart={this.removeLineItemInCart}
			                	/>
							</Drawer>
						</Toolbar>
					</AppBar>
				</div>

				<AppBar className="not-mobile" position="sticky" style={{ background: '#f1f3f4' }} elevation={0}>
					<Toolbar>
						<NavLink className="nav-item" to="/">
							<img src={require('./logo/NP_logo_A_bloody.png')} width="265" alt="Newfangle Press"/>
						</NavLink>

						<NavLink className="nav-item" to={{ pathname: '/catalog', state: 'refreshStore' }}>
							<Button color="inherit">
								<Typography variant="h6" className="title" style={createStyle('navTypography')}>Catalog</Typography>
							</Button>
						</NavLink>
						<NavLink className="nav-item" to="/submissions">
							<Button color="inherit">
								<Typography variant="h6" className="title" style={createStyle('navTypography')}>Submissions</Typography>
							</Button>
						</NavLink>
						<NavLink className="nav-item" to="/events">
							<Button color="inherit">
								<Typography variant="h6" className="title" style={createStyle('navTypography')}>Events</Typography>
							</Button>
						</NavLink>
						<NavLink className="nav-item title" to="/contact">
							<Button color="inherit">
								<Typography variant="h6" className="title" style={createStyle('navTypography')}>Contact</Typography>
							</Button>
						</NavLink>

						<Button color="inherit" onClick={this.handleCartOpen} style={{ display: "none" }}>
							<FontAwesomeIcon icon={faShoppingCart} size="lg" color="#585A5C" className="title"/>
						</Button>
						<Drawer anchor='right' open={this.props.isCartOpen} onClose={this.handleCartClose}>
							<Cart
		                    	checkout={this.props.checkout}
		                    	isCartOpen={this.props.isCartOpen}
		                    	handleCartClose={this.handleCartClose}
		                    	updateQuantityInCart={this.updateQuantityInCart}
		                    	removeLineItemInCart={this.removeLineItemInCart}
		                	/>
						</Drawer>
					</Toolbar>
				</AppBar>

				<Collapse in={this.props.isAlertOpen} style={{ position: "fixed", zIndex: "200" }}>
					<Alert severity={this.props.alertSeverity}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={this.handleAlertClose}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						<AlertTitle>{this.props.alertTitle}</AlertTitle>
						{this.props.alertMessage}
					</Alert>
				</Collapse>

				<Route exact path="/" component={About}/>
				<Route exact path="/catalog" component={ShopifyBuyNow}/>
				<Route exact path="/catalog/the-ingenue" component={Product1}/>
				<Route exact path="/catalog/never-sleep-again" component={Product6}/>
				<Route path="/submissions" component={Submissions}/>
				<Route path="/events" component={Events}/>
				<Route path="/contact" component={ContactPage}/>

				<AppBar position="fixed" style={{ top: "auto", bottom: "0", backgroundColor: "rgb(250,250,250,1)", textAlign: "center" }} elevation={0} className="footerBar">
					<Toolbar style={{ textAlign: "center" }}>
						<a href="https://facebook.com/newfanglepress" style={{ marginLeft: "auto", marginRight: "4px" }}>
							<FontAwesomeIcon icon={faFacebookSquare} size="2x" color="#585A5C"/>
						</a>
						<a href="https://twitter.com/NewfanglePress" style={{ marginRight: "auto", marginLeft: "4px" }}>
							<FontAwesomeIcon icon={faTwitterSquare} size="2x" color="#585A5C"/>
						</a>
					</Toolbar>
					<div style={{ backgroundColor: "#585A5C" }}>
						<Typography style={createStyle('siteTypography')}>Copyright &#169; 2020, 2024 Newfangle Media LLC &#9670; Cincinnati, Ohio &#9670; USA</Typography>
					</div>
				</AppBar>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		handleCartClose: () => {
            dispatch(handleCartClose());
		},
        handleCartOpen: () => {
            dispatch(handleCartOpen());
		},
		handleAlertClose: () => {
			dispatch(handleAlertClose());
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
		alertTitle: state.alert.alertTitle,
        alertSeverity: state.alert.alertSeverity,
        alertMessage: state.alert.alertMessage,
        isAlertOpen: state.alert.isAlertOpen,
		isProgressOpen: state.alert.isProgressOpen,
		checkout: state.cart.checkout,
        isCartOpen: state.cart.isCartOpen
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
