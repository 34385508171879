import React, { Component } from 'react';
import '../App.css';
import quotes from './QuoteStore';

class QuoteBubble extends Component {
	constructor(props) {
		super(props);

        const generateRandomQuote = this.randomQuote();
        console.log(generateRandomQuote);
        this.state = {
            quote: generateRandomQuote.quote,
			quoteLines: generateRandomQuote.quoteLines,
            author: generateRandomQuote.author,
            source: generateRandomQuote.source
        };
    }

    randomQuote() {
        const randomNumber = Math.floor(Math.random() * quotes.length);
        return quotes[randomNumber];
    }

    render() {
        var attribution = "Unknown";
        if (this.state['author']) {
            attribution = this.state['author'];
            if (this.state['source']) {
                attribution += ", " + this.state['source'];
            }
        }
        else if (this.state['source']) {
            attribution = this.state['source'];
        }

        return (
            <div style={{ backgroundColor: "#ffffe6", borderRadius: "0px" }} className="quotebox">
                <div className="fadeIn" key={Math.random()} style={{   }}>
					{
						this.state['quoteLines'] &&
						this.state.quoteLines.map(function(line, index) {
							return (
								<h4 id="quote" style={{ color: "#3e4f5b", fontFamily: "SpecialElite", marginBlockStart: "10px", paddingTop: "0px", paddingLeft: "8px", paddingRight: "8px", paddingBottom: "0px", marginBottom: "0", marginTop: "0" }}>{line}</h4>
							);
						})
					}

					{
						this.state['quote'] &&
						<h4 id="quote" style={{ color: "#3e4f5b", fontFamily: "SpecialElite", marginBlockStart: "10px", paddingTop: "8px", paddingLeft: "8px", paddingRight: "8px" }}>&#x201c;{this.state['quote']}&#x201d;</h4>
					}

                    <h5 id="author" style={{ color: "#3e4f5b", fontFamily: "SpecialElite", paddingBottom: "8px", paddingLeft: "8px", paddingRight: "8px" }}>&#8212;{attribution}&#8212;</h5>
                </div>
            </div>
        );
    }
}

/*const mapStateToProps = (state, ownProps) => {
	return {
		active: ownProps.blogs === state.blogs
	}
}

export default connect(mapStateToProps)(QuoteBubble);*/

export default QuoteBubble;
