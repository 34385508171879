import React, { Component } from 'react';
import './App.css';
import BuyNow from "./BuyNow";
import Grid from '@material-ui/core/Grid';

class Product6 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFlushed: false
		};
	}

    componentDidMount() {
        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                this.ShopifyBuyInit();
            }
            else {
                this.loadScript();
            }
        }
        else {
            this.loadScript();
        }
    }

    loadScript() {
        var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
        var script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = this.ShopifyBuyInit;
    }

    ShopifyBuyInit() {
        var client = window.ShopifyBuy.buildClient({
            domain: 'newfangle-llc.myshopify.com',
            storefrontAccessToken: 'a756723c50787179031f4335cd6feda4',
        });

        window.ShopifyBuy.UI.onReady(client).then(function (ui) {
			/* Never Sleep Again */
            ui.createComponent('product', {
                id: '8105599729861',
                node: document.getElementById('product-component-never-sleep-again'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });
        });
    }

    scriptLoaded() {
    }

    render() {
    	return (
			<div style={{ marginBottom: "100px" }}>
			<Grid container direction="row" justify="center" alignItems="flex-end">
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-never-sleep-again' style={{ paddingBottom: '12px' }}/>
				</Grid>
			</Grid>
			</div>
    	);
    }
}

/*const mapStateToProps = (state, ownProps) => {
	return {
		active: ownProps.blogs === state.blogs
	}
}

export default connect(mapStateToProps)(Shop);*/

export default Product6;
