import React, { Component } from 'react';
import './App.css';
import QuoteBubble from "./quotes/QuoteBubble";
import Blog from "./blog/Blog";
import { Container, Row, Col } from 'reactstrap';
import { createStyle } from "./styling";
import Typography from '@material-ui/core/Typography';

class About extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

    render() {
		return (
            <Container>
                <Row>
                    <Col xs="12" sm="7">
                        <div style={{ marginBlockStart: "10px" }}>
                        <span className="site-text">Founded in 2020, the mission statement of Newfangle Press
                        is simple</span><span style={{ fontFamily: "TimesLTStdRoman" }}>&#8212;</span><span className="site-text">to encourage and promote new talent. Your
                        story should be part of the global consciousness, and the
                        journey to achieve that goal should not be daunting.</span>
                        </div>
                    </Col>
                    <Col sm="5">
                        <QuoteBubble/>
                    </Col>
                </Row>
				<Row>
					<Col xs="12" sm="12" style={{ backgroundColor: "#585A5C" }}>
						<Typography variant="h6" className="title.dark" style={createStyle('siteTypography', { 'fontWeight': 'bold', 'color': 'white' })}>From the Press</Typography>
					</Col>
				</Row>
				<Row>
					<Col xs="12" sm="12">
						<Blog/>
					</Col>
				</Row>
            </Container>
		);
	}
}

/*const mapStateToProps = (state, ownProps) => {
	return {
		active: ownProps.blogs === state.blogs
	}
}

export default connect(mapStateToProps)(About);*/

export default About;
