// Action Types

export const CLIENT_CREATED = 'CLIENT_CREATED'
export const PRODUCTS_FOUND = 'PRODUCTS_FOUND'
export const CHECKOUT_FOUND = 'CHECKOUT_FOUND'
export const SHOP_FOUND = 'SHOP_FOUND'

export const ADD_VARIANT_TO_CART = 'ADD_VARIANT_TO_CART'
export const UPDATE_QUANTITY_IN_CART = 'UPDATE_QUANTITY_IN_CART'
export const REMOVE_LINE_ITEM_IN_CART = 'REMOVE_LINE_ITEM_IN_CART'
export const OPEN_CART = 'OPEN_CART'
export const CLOSE_CART = 'CLOSE_CART'

// Other Constants


// Action creators

export function handleCartClose() {
    return { type: CLOSE_CART }
}

export function handleCartOpen() {
    return { type: OPEN_CART }
}

export function addVariantToCart(res) {
    return { type: ADD_VARIANT_TO_CART, payload: { isCartOpen: true, checkout: res } }
}
