import React from 'react';
import TextField from '@material-ui/core/TextField';

export function serializeData(data, ...includedProperties) {
    var formBody = [];
    for (var property in data) {
        var includeProperty = true;
        if (includedProperties.length > 0) {
            includeProperty = includedProperties.includes(property);
        }

        if (includeProperty) {
            var encodedProperty = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedProperty + "=" + encodedValue);
        }
    }
    formBody = formBody.join("&");
    return formBody;
}

export function renderTextField({ input, label, meta: { touched, error }, ...custom }) {
    return (
        <TextField error={Boolean(touched && error)} label={label} helperText={error} {...input} {...custom}/>
    );
}


/*export default function renderTextField({ input, label, type, meta: { touched, error, warning } }) {
    return (
        //<TextField hintText={label} floatingLabelText={label} errorText={touched && error} {...input} {...custom}/>
        <div>
            <TextField label={label} type={type} {...input}/>
            {touched && ((error && <div>{error}</div>) || (warning && <div>{warning}</div>))}
        </div>
    );
}*/

// THIS ONE ESSENTIALLY WORKS!!!!
/*export default function renderTextField({ input, label, meta: { touched, error }, ...custom }) {
    return (
        //<TextField hintText={label} floatingLabelText={label} errorText={touched && error} {...input} {...custom}/>
        <TextField label={label} {...input} {...custom}/>
    );
}*/

/*export default function renderTextField({ label }) {
    return (
        <TextField required label={label}/>
    );
}*/
