import {  } from '../actions/siteActions'

const initialState = {
    recaptchaSiteKey: '6LemsNoZAAAAAPHioPjmdp0lWCF10e-zsOIQ2sfa'
}

export default function alert(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}
