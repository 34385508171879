import React, { Component } from 'react';
import './App.css';

class EcwidCatalog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFlushed: false
		};
	}

    componentDidMount() {
		const script = document.createElement("script");
	    script.src = "https://app.ecwid.com/script.js?38231004&data_platform=code&data_date=2020-10-10";
	    script.async = true;
	    script.onload = () => this.scriptLoaded();
	    document.body.appendChild(script);

		if (!this.state.isFlushed && this.props.location.state === 'refreshStore') {
			this.setState({
				isFlushed: true,
			}, () => this.refreshStore());
		}
    }

	refreshStore() {
		//window.location.reload();
	}

	scriptLoaded() {
		//window.xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-38231004");

		window.ecwid_script_defer = true;

		var script = document.createElement('script');
		script.charset = 'utf-8';
		script.type = 'text/javascript';
		script.src = 'https://app.ecwid.com/script.js?38231004';

		document.getElementById('my-store-38231004').appendChild(script);

		window._xnext_initialization_scripts = [
			{
				widgetType: 'ProductBrowser',
				id: 'my-store-38231004',
				arg: [
					'id=my-store-38231004'
				]
			},
			{
				widgetType: 'CategoriesV2',
				id: 'id=my-categories-38231004',
				arg: [
					'id=my-categories-38231004'
				]
			},
			{
				widgetType: 'SearchWidget',
				id: 'my-search-38231004',
				arg: [
					'"id=my-search-38231004"'
				]
			},
			{
				widgetType: 'SingleProduct',
				id: 'Product-1',
				arg: [
					'id=Product-1'
				]
			}
		];

		window.Ecwid.init();
    }

	render() {
		/*
		<div id="my-categories-38231004"></div>
		<div id="my-search-38231004"></div>
		<div class="ec-cart-widget"></div>
		*/
		return (
            <div>
                <div id="my-store-38231004"></div>
            </div>
		);
	}
}

/*const mapStateToProps = (state, ownProps) => {
	return {
		active: ownProps.blogs === state.blogs
	}
}

export default connect(mapStateToProps)(EcwidCatalog);*/

export default EcwidCatalog;
