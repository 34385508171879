const quotes = [
    {
        "quote": "Genius is one percent inspiration and ninety-nine percent perspiration.",
        "author": "Thomas Edison"
    },
    {
        "quote": "Whatever seems like coincidence, rarely is.",
        "author": "William Wager",
        "source": "The Ingénue"
    },
    {
        "quote": "I’ve never seen a point in apologizing for other people’s actions.",
        "author": "William Wager",
        "source": "The Ingénue"
    },
    {
        "quote": "If we wait until we’re ready we’ll be waiting for the rest of our lives.",
        "author": "Lemony Snicket",
        "source": "The Ersatz Elevator"
    },
    {
        "quote": "Behind every exquisite thing that existed, there was something tragic.",
        "author": "Oscar Wilde",
        "source": "The Picture of Dorian Gray"
    },
    {
        "quote": "By accepting a given world-view as absolute truth, people are scooping up a handful of sand and thereby ignoring the rest of the beach.",
        "author": "Stewart Wieck",
        "source": "Mage: The Ascension"
    },
    {
        "quote": "All the days ordained for me were written in your book before one of them came to be.",
        "source": "Psalms 139:16"
    },
    {
        "quoteLines": ["There once was a lit'rary miss;","And all that she needed for bliss","Was some ink and a pen,","Reams of paper, and then","Thirty days to describe half a kiss."],
        "author": "Oliver Marble",
        "source": "A Literary Miss"
    }
]
export default quotes
