import { OPEN_ALERT, CLOSE_ALERT, OPEN_PROGRESS, CLOSE_PROGRESS } from '../actions/alertActions'

const initialState = {
    isAlertOpen: false,
    alertTitle: '',
    alertMessage: '',
    alertSeverity: '',
    isProgressOpen: false
}

export default function alert(state = initialState, action) {
    switch (action.type) {
        case OPEN_ALERT:
            return {...state, isAlertOpen: true, alertTitle: action.payload.title, alertMessage: action.payload.message, alertSeverity: action.payload.severity}
        case CLOSE_ALERT:
            return {...state, isAlertOpen: false}
        case OPEN_PROGRESS:
            return {...state, isProgressOpen: true}
        case CLOSE_PROGRESS:
            return {...state, isProgressOpen: false}
        default:
            return state
    }
}
