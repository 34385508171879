import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import configureStore from './redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import Client from 'shopify-buy';

import axios from 'axios';
import { getAccessToken } from './auth/AuthService'

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        const token = getAccessToken();
        if (token) {
            //config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

const store = configureStore();

const client = Client.buildClient({
    storefrontAccessToken: 'a756723c50787179031f4335cd6feda4',
    domain: 'newfangle-llc.myshopify.com'
});
store.dispatch({type: 'CLIENT_CREATED', payload: client});

client.product.fetchAll().then((res) => {
    store.dispatch({type: 'PRODUCTS_FOUND', payload: res});
});
client.checkout.create().then((res) => {
    store.dispatch({type: 'CHECKOUT_FOUND', payload: res});
});
client.shop.fetchInfo().then((res) => {
    store.dispatch({type: 'SHOP_FOUND', payload: res});
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename={"/"} forceRefresh="true">
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
