import React, { Component } from "react";

class DateBadge extends Component {
	constructor(props) {
		super(props);

		let date = new Date(props.startDate.dateTime ? props.startDate.dateTime : props.startDate.date);
		let dayOfWeek = this.getDayOfWeek(props.startDate.dateTime ? date.getDay() : date.getUTCDay());
		let month = this.getMonth(props.startDate.dateTime ? date.getMonth() : date.getUTCMonth());
		let dayOfMonth = props.startDate.dateTime ? date.getDate() : date.getUTCDate();

		this.state = {
			dayOfMonth: dayOfMonth,
			dayOfWeek: dayOfWeek,
			month: month,
			attachments: props.attachments
		}
	}

	getMonth(num) {
		var month = "JAN";

		switch (num) {
			case 1:
				month = "FEB";
				break;
			case 2:
				month = "MAR";
				break;
			case 3:
				month = "APR";
				break;
			case 4:
				month = "MAY";
				break;
			case 5:
				month = "JUN";
				break;
			case 6:
				month = "JUL";
				break;
			case 7:
				month = "AUG";
				break;
			case 8:
				month = "SEP";
				break;
			case 9:
				month = "OCT";
				break;
			case 10:
				month = "NOV";
				break;
			case 11:
				month = "DEC";
				break;
			default:
				month = "JAN";
				break;
		}

		return month;
	}

	getDayOfWeek(num) {
		var dayOfWeek = "SUN";

		switch (num) {
			case 1:
				dayOfWeek = "MON";
				break;
			case 2:
				dayOfWeek = "TUE";
				break;
			case 3:
				dayOfWeek = "WED";
				break;
			case 4:
				dayOfWeek = "THU";
				break;
			case 5:
				dayOfWeek = "FRI";
				break;
			case 6:
				dayOfWeek = "SAT";
				break;
			default:
				dayOfWeek = "SUN";
				break;
		}

		return dayOfWeek;
	}

	render() {
		return (
			<div>
				<div className="date-badge-header">
					<div className="date-badge-header-text" style={{  }}>
					{this.state.dayOfWeek} {this.state.month} {this.state.dayOfMonth}
					</div>
				</div>
				<div className="date-badge-header-image">
					<img src={require('./day-icons/day-' + this.state.dayOfMonth + '.png')} width="48" alt={this.state.dayOfMonth}/>
				</div>
			</div>
		);
	}
}

export default DateBadge;
