import React, { Component } from "react";
import "./Event.css";
import DateBadge from "../components/DateBadge";
import { Container, Row, Col } from 'reactstrap';
import Parser from 'html-react-parser';
import Button from '@material-ui/core/Button';

class Event extends Component {
	constructor(props) {
		super(props);

		let location = props.event.location ? "&location=" + props.event.location : "";

		let startDate = new Date(props.event.start.dateTime ? props.event.start.dateTime : props.event.start.date);
		let startYear = props.event.start.dateTime ? startDate.getYear() : startDate.getUTCFullYear();
		let startMonth = (1 + (props.event.start.dateTime ? startDate.getMonth() : startDate.getUTCMonth())).toString().padStart(2, "0");
		let startDayOfMonth = (props.event.start.dateTime ? startDate.getDate() : startDate.getUTCDate()).toString().padStart(2, "0");

		// TODO display times properly in the link ('T' + HHMMSS)
		let startTime = props.event.start.dateTime ? 'T' : '';

		let start = startYear + '' + startMonth + '' + startDayOfMonth + startTime;

		let endDate = new Date(props.event.end.dateTime ? props.event.end.dateTime : props.event.end.date);
		let endYear = props.event.end.dateTime ? endDate.getYear() : endDate.getUTCFullYear();
		let endMonth = (1 + (props.event.end.dateTime ? endDate.getMonth() : endDate.getUTCMonth())).toString().padStart(2, "0");
		let endDayOfMonth = (props.event.end.dateTime ? endDate.getDate() : endDate.getUTCDate()).toString().padStart(2, "0");

		// TODO display times properly in the link ('T' + HHMMSS)
		let endTime = props.event.end.dateTime ? 'T' : '';

		let end = endYear + '' + endMonth + '' + endDayOfMonth + endTime;

		this.state = {
			isPast: startDate < new Date(),
			summary: props.event.summary,
			description: props.event.description,
			attachments: props.event.attachments,
			start: props.event.start,
			link: 'http://www.google.com/calendar/event?action=TEMPLATE&dates=' + start + '/' + end + '&text=' + props.event.summary + location
		}
	}

	render() {
		let addToCalendar = this.state.isPast ? null :
		(<div>
			<Button variant="contained" color="primary" href={this.state.link} target="_blank">Add to calendar</Button>
		</div>);

		return (
			<Container className={this.props.rowClassName}>
                <Row>
					<Col xs="3" sm="3">
						<div className="date-badge">
							<DateBadge startDate={this.state.start} attachments={this.state.attachments}/>
						</div>
					</Col>
                    <Col xs="9" sm="5">
						<div className="event-badge">
							<div className="event-title" style={{ fontFamily: "SpecialElite" }}>{Parser(this.state.summary)}</div>
							<div className="site-text">{this.state.description ? Parser(this.state.description) : ""}</div>
							{addToCalendar}
						</div>
                    </Col>
					<Col xs="12" sm="4">
                        <div className="event-image">
                        {
                            (this.state.attachments && this.state.attachments.length > 0) &&
                            <img src={"http://drive.google.com/uc?export=view&id=" + this.state.attachments[0].fileId} maxwidth="140" alt="attachment unavailable"/>
                        }
                        </div>
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default Event;
