import React, { Component } from 'react'
import ContactForm from "./forms/contact";
import { Container, Row, Col } from 'reactstrap';
import { Link  } from "react-router-dom";

class ContactPage extends Component {
	render() {
    	return (
			<Container>
                <Row>
                    <Col xs="12" sm="7">
						<div className="site-text">
						If you are interested in submitting your work for publication, visit the <Link to="/submissions">Submissions</Link> page. For all other comments or queries, you can reach out on social media, or fill out the form below to send an email.
						</div>
						<ContactForm/>
                    </Col>
                    <Col sm="5">
                    </Col>
                </Row>
            </Container>
    	);
	}
}

export default ContactPage;
