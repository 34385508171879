import React, { Component } from 'react';
import Products from './components/shopify/Products';
import { connect } from 'react-redux';
import { addVariantToCart } from './redux/actions/cartActions';

class ShopifyProducts extends Component {
	constructor(props) {
		super(props);
		//this.addVariantToCart = this.props.addVariantToCart.bind(this);
		this.addVariantToCart = this.addVariantToCart.bind(this);
	}

	addVariantToCart(variantId, quantity) {
		const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
		const checkoutId = this.props.checkout.id
		this.props.client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
			this.props.addVariantToCart(res);
		});
	}

	render() {
		return (
			<div style={{ marginBottom: "100px" }}>
				<Products
					products={this.props.products}
					client={this.props.client}
					addVariantToCart={this.addVariantToCart}
				/>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
		addVariantToCart: (checkoutRes) => {
			dispatch(addVariantToCart(checkoutRes));
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
		products: state.cart.products,
		client: state.cart.client,
		checkout: state.cart.checkout
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyProducts);
