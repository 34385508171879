import React, { Component } from "react";
import "./Blog.css";
import BlogPost from "./BlogPost";
import axios from "axios";
import { Container, Row, Col } from 'reactstrap';

class Blog extends Component {
	constructor(props) {
		super(props);

		this.state = {
			events: []
		};

		let blogId = "1808536030231193392";
		let apiKey = "AIzaSyCzlc3c_lHNrg6cM2hFon-fbGnunENMKVo";
		let url = "https://www.googleapis.com/blogger/v3/blogs/" + blogId + "/posts?key=" + apiKey;
		let self = this;
		axios.get(url)
			.then(res => {
				let items = self.orderByDate(res.data.items, "published", "dateTime");
				self.setState({
					events: items
				});
				console.log(self.state);
			}
		)
	}

	orderByDate(arr, dateProp, dateProp2) {
		return arr.slice().sort(function (a, b) {
			return a[dateProp][dateProp2] < b[dateProp][dateProp2] ? -1 : 1;
		});
	}

	render() {
		return (
			<div style={{ marginBottom: "100px" }}>
			<Container>
                <Row className={this.props.rowClassName}>
					<Col xs="12" sm="11">
						<div className="events">
						{
							(this.state && this.state.events.length > 0) &&
							this.state.events.map(function(event, index) {
								return (
									<BlogPost isOldPost={index !== 0} key={event.id} event={event} rowClassName={ (index % 2 === 1) ? "odd-row" : "even-row"}/>
								);
							})
						}
						</div>
                    </Col>
					<Col sm="1">
                    </Col>
                </Row>
            </Container>
			</div>
		);
	}
}

export default Blog;
