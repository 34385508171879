import React, { Component } from 'react';
import Product from './Product';
import Grid from '@material-ui/core/Grid';
import './Shopify.css';

class Products extends Component {
    render() {
        let products = this.props.products.map((product) => {
            return (
                <Grid item sm xs={12}><Product maxWidth="200px"
                    addVariantToCart={this.props.addVariantToCart}
                    client={this.props.client}
                    key={product.id.toString()}
                    product={product}
                /></Grid>
            );
        });

        return (
            <div className="Product-wrapper">
                <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                {products}
                </Grid>
            </div>
        );
    }
}

export default Products;
