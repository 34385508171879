import React, { Component } from 'react'
import Recaptcha from 'react-gcaptcha'
import './SubmissionForm.css'
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { Container, Row, Col } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { connect } from 'react-redux';
import { handleAlertOpen, handleProgressOpen, handleProgressClose } from '../redux/actions/alertActions';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Dropzone from "react-dropzone"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';

class SubmissionForm extends Component {
	constructor(props) {
		super(props);

		this.onCaptchaLoad = this.onCaptchaLoad.bind(this);
		window.onloadCallback = this.onCaptchaLoad;

		this.onCaptchaVerify = this.onCaptchaVerify.bind(this);
		window.verifyCallback = this.onCaptchaVerify;

        this.handleAlertOpen = this.props.handleAlertOpen.bind(this);
        this.handleProgressOpen = this.props.handleProgressOpen.bind(this);
        this.handleProgressClose = this.props.handleProgressClose.bind(this);

		this.state = {
            nameInput: '',
			emailInput: '',
            bookTitleInput: '',
            firstGenreInput: '',
            secondGenreInput: '',
            targetAudienceInput: '',
            queryInput: '',
            bioInput: '',
            fileInput: '',
            fileSubmissions: [],

            nameInputDirty: false,
            emailInputDirty: false,
            bookTitleInputDirty: false,
            wordCountInputDirty: false,
            firstGenreInputDirty: false,
            secondGenreInputDirty: false,
            targetAudienceInputDirty: false,
            queryInputDirty: false,
            bioInputDirty: false,
            fileInputDirty: false,

		    isCaptchaValid: false
		};
	}

    resetForm() {
        this.setState({
            nameInput: '',
			emailInput: '',
            bookTitleInput: '',
            firstGenreInput: '',
            secondGenreInput: '',
            targetAudienceInput: '',
            queryInput: '',
            bioInput: '',
            fileInput: '',
            fileSubmissions: [],

            nameInputDirty: false,
            emailInputDirty: false,
            bookTitleInputDirty: false,
            wordCountInputDirty: false,
            firstGenreInputDirty: false,
            secondGenreInputDirty: false,
            targetAudienceInputDirty: false,
            queryInputDirty: false,
            bioInputDirty: false,
            fileInputDirty: false,

		    isCaptchaValid: false
        });

        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({
            itemvalues: [{}]
        });
    }

	componentDidMount() {
		const script = document.createElement("script");
	    script.src = "https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit";
	    script.async = true;
	    script.onload = () => this.scriptLoaded();
	    document.head.appendChild(script);
	}

	scriptLoaded() {
	}

	onCaptchaLoad() {
	}

    handleBlur(event) {
        this.setState({
            [event.target.id + 'InputDirty']: true
        });
        this[event.target.id + 'Validation'](event);
    }

    handleInput(event) {
        this.setState({
            [event.target.id + 'InputDirty']: true
        });
        this[event.target.id + 'Validation'](event);
    }

    nonBlankValidation(event) {
        if (event.target.value.length === 0) {
            this.setState({
                [event.target.id + 'Input']: '',
                [event.target.id + 'InputError']: true
            });
        }
        else {
            this.setState({
                [event.target.id + 'Input']: event.target.value,
                [event.target.id + 'InputError']: false
            });
        }
    }

    nameValidation(event) {
        this.nonBlankValidation(event);
    }

    messageValidation(event) {
        this.nonBlankValidation(event);
    }

    queryValidation(event) {
        this.nonBlankValidation(event);
    }

    bioValidation(event) {
        this.nonBlankValidation(event);
    }

    bookTitleValidation(event) {
        this.nonBlankValidation(event);
    }

    wordCountValidation(event) {
        if (event.target.value.length === 0 || Number(event.target.value) < 1) {
            this.setState({
                [event.target.id + 'Input']: '',
                [event.target.id + 'InputError']: true
            });
        }
        else {
            this.setState({
                [event.target.id + 'Input']: event.target.value,
                [event.target.id + 'InputError']: false
            });
        }
    }

    firstGenreValidation(event) {
        let id = 'firstGenre';

        if (event.target.value.length === 0) {
            this.setState({
                [id + 'InputError']: true
            });
        }
        else {
            this.setState({
                [id + 'Input']: event.target.value,
                [id + 'InputError']: false
            });
        }
    }

    secondGenreValidation(event) {
        let id = 'secondGenre';
        this.setState({
            [id + 'Input']: event.target.value
        });
    }

    targetAudienceValidation(event) {
        let id = 'targetAudience';

        if (event.target.value.length === 0) {
            this.setState({
                [id + 'InputError']: true
            });
        }
        else {
            this.setState({
                [id + 'Input']: event.target.value,
                [id + 'InputError']: false
            });
        }
    }

	emailValidation(event) {
        // eslint-disable-next-line
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (event.target.value.length === 0) {
            this.setState({
                emailInputErrorText: null,
                emailInputError: true
            });
        }
        else if (reg.test(String(event.target.value).toLowerCase())) {
            this.setState({
                emailInput: event.target.value,
                emailInputErrorText: null,
                emailInputError: false
            });
        }
        else {
            this.setState({
                emailInputErrorText: 'Invalid email address',
                emailInputError: true
            });
        }
	}

    fileValidation(acceptedFiles) {
        let id = 'file';

        if (acceptedFiles[0].name.endsWith(".pdf") || acceptedFiles[0].name.endsWith(".doc") || acceptedFiles[0].name.endsWith(".docx")) {
            this.setState({
                fileSubmissions: acceptedFiles,
                [id + 'InputError']: false
    		});
        }
        else {
            this.setState({
                fileSubmissions: [],
                [id + 'InputError']: true
    		});
        }
    }

    removeFile(args) {
        this.setState({
            fileInputDirty: true,
            fileSubmissions: []
		})
    }

	// Update state after reCaptcha validates visitor
	onCaptchaVerify(response) {
		this.setState({
			isCaptchaValid: true
		})
	}

    isValid() {
        return (this.state.fileSubmissions.length === 1 &&
            this.state.nameInput.length > 0 &&
            this.state.emailInput.length > 0 &&
            this.state.bookTitleInput.length > 0 &&
            this.state.wordCountInput &&
            this.state.wordCountInput > 0 &&
            this.state.firstGenreInput.length > 0 &&
            this.state.targetAudienceInput.length > 0 &&
            this.state.queryInput.length > 0 &&
            this.state.bioInput.length > 0 &&
            this.state.isCaptchaValid
        )
    }

    handleFormSubmit = event => {
        let self = this;

        if (this.isValid()) {
            let acceptedFiles = this.state.fileSubmissions;
            acceptedFiles.forEach((file) => {
                let form = {
                    name: self.state.nameInput,
                    title: self.state.bookTitleInput,
                    fileName: file.name
                };

                let formData = new FormData();
                formData.append('file', file);
                formData.append('name', self.state.nameInput);
                formData.append('email', self.state.emailInput);
                formData.append('title', self.state.bookTitleInput);
                formData.append('wordCount', self.state.wordCountInput);
                formData.append('firstGenre', self.state.firstGenreInput);
                formData.append('secondGenre', self.state.secondGenreInput);
                formData.append('targetAudience', self.state.targetAudienceInput);
                formData.append('query', self.state.queryInput);
                formData.append('bio', self.state.bioInput);
                formData.append('fileName', file.name);
				formData.append('mimeType', file.type);

                // Submit the form
                self.handleProgressOpen();
                const res = axios.post(
                    '/rest/submission',
                    formData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }
                )

                // handle success
                .then(function (response) {
                    // Reset state after successfully sending the form
                    self.resetForm();

                    self.handleAlertOpen({ title: 'Success', severity: 'success', message: 'Your submission was successfully delivered. Newfangle Press will contact you soon.' });
                })

                // handle error
                .catch(function (error) {
                    console.log(error);
                    self.handleAlertOpen({ title: 'Error', severity: 'error', message: 'An error occurred and your submission could not be delivered.' });
                })

                // always executed
                .then(function () {
                    console.log(res)
                    self.handleProgressClose();
                });

                // If it turns out we need to read the file, this is how.
                /*const reader = new FileReader()

                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.onload = () => {
                    // Handle the file contents
                    const binaryStr = reader.result
                    console.log(binaryStr)

                }

                // Read the selected file
                reader.readAsArrayBuffer(file)*/
            });
        }
    }

	render() {
    	return (
            <Container>
                <Row>
                    <Col xs="12" sm="12">
                        <TextField required id="name" label="Your Name" style={{ "width": "80%" }} onChange={this.handleInput.bind(this)} onBlur={this.handleBlur.bind(this)} helperText={this.state.nameInputErrorText} error={this.state.nameInputError}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12">
                        <TextField required id="email" label="Your Email" style={{ "width": "80%" }} type="email" onChange={this.handleInput.bind(this)} onBlur={this.handleBlur.bind(this)} helperText={this.state.emailInputErrorText} error={this.state.emailInputError}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="5">
                        <TextField required id="bookTitle" label="Book Title" style={{ "width": "80%" }} onChange={this.handleInput.bind(this)} onBlur={this.handleBlur.bind(this)} helperText={this.state.bookTitleInputErrorText} error={this.state.bookTitleInputError}/>
                    </Col>
                    <Col xs="12" sm="5">
                        <TextField required id="wordCount" label="Word Count" type="number" style={{ "width": "80%" }} onChange={this.handleInput.bind(this)} onBlur={this.handleBlur.bind(this)} helperText={this.state.wordCountInputErrorText} error={this.state.wordCountInputError}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="5">
                        <FormControl style={{ "width": "80%" }}>
                            <InputLabel shrink id="firstGenreLabel">
                                {this.state.firstGenreInput ? "First Genre*" : "" }
                            </InputLabel>
                            <Select
                                labelId="firstGenreLabel"
                                id="firstGenre"
                                value={this.state.firstGenreInput}
                                onChange={this.firstGenreValidation.bind(this)}
                                displayEmpty
                                autoWidth
                            >
                                <MenuItem value="" disabled>First Genre *</MenuItem>
                                <MenuItem value={'AGRICULTURE'}>Agriculture</MenuItem>
                                <MenuItem value={'ARCHITECTURE'}>Architecture</MenuItem>
                                <MenuItem value={'ART'}>Art</MenuItem>
                                <MenuItem value={'ASTROLOGY'}>Astrology</MenuItem>
                                <MenuItem value={'BIBLE_COMMENTARIES'}>Bible Commentaries</MenuItem>
                                <MenuItem value={'BIOGRAPHY'}>Biography</MenuItem>
                                <MenuItem value={'BUSINESS'}>Business</MenuItem>
                                <MenuItem value={"CHILDREN'S FICTION"}>Children's Fiction</MenuItem>
                                <MenuItem value={'COLLECTORS AND COLLECTING'}>Collectors and Collecting</MenuItem>
                                <MenuItem value={'COMICS AND GRAPHIC NOVELS_GENERAL'}>Comics and Graphic Novels (General)</MenuItem>
                                <MenuItem value={'COMPUTER SOFTWARE'}>Computer Software</MenuItem>
                                <MenuItem value={'COMPUTERS'}>Computers</MenuItem>
                                <MenuItem value={'COOKING'}>Cooking</MenuItem>
                                <MenuItem value={'CRIME'}>Crime</MenuItem>
                                <MenuItem value={'CURIOSITIES AND WONDERS'}>Curiosities and Wonders</MenuItem>
                                <MenuItem value={'DRAMA (DRAMATIC WORKS BY ONE AUTHOR)'}>Drama (Dramatic works by one author)</MenuItem>
                                <MenuItem value={'DRAMA_COLLECTIONS'}>Drama: Collections</MenuItem>
                                <MenuItem value={'DRAMA_HISTORY AND CRITICISM'}>Drama: History and Criticism</MenuItem>
                                <MenuItem value={'ECONOMICS'}>Economics</MenuItem>
                                <MenuItem value={'EDUCATION'}>Education</MenuItem>
                                <MenuItem value={'FAMILY'}>Family</MenuItem>
                                <MenuItem value={'FICTION_ACTION AND ADVENTURE'}>Fiction: Action and Adventure</MenuItem>
                                <MenuItem value={'FICTION_EROTICA'}>Fiction: Erotica</MenuItem>
                                <MenuItem value={'FICTION_ESPIONAGE'}>Fiction: Espionage</MenuItem>
                                <MenuItem value={'FICTION_FANTASY_GENERAL'}>Fiction: Fantasy (General)</MenuItem>
                                <MenuItem value={'FICTION_GAY'}>Fiction: Gay</MenuItem>
                                <MenuItem value={'FICTION_GENERAL'}>Fiction: General</MenuItem>
                                <MenuItem value={'FICTION_HISTORICAL'}>Fiction: Historical</MenuItem>
                                <MenuItem value={'FICTION_HORROR'}>Fiction: Horror</MenuItem>
                                <MenuItem value={'FICTION_MYSTERY AND DETECTIVE_GENERAL'}>Fiction: Mystery and Detective</MenuItem>
                                <MenuItem value={'FICTION_PSYCHOLOGICAL'}>Fiction: Psychological</MenuItem>
                                <MenuItem value={'FICTION_RELIGIOUS'}>Fiction: Religious</MenuItem>
                                <MenuItem value={'FICTION_ROMANCE_GENERAL'}>Fiction: Romance (General)</MenuItem>
                                <MenuItem value={'FICTION_SCIENCE FICTION_GENERAL'}>Fiction: Science Fiction (General)</MenuItem>
                                <MenuItem value={'FICTION_SHORT STORIES (SINGLE AUTHOR)'}>Fiction: Short Stories (Single author)</MenuItem>
                                <MenuItem value={'FICTION_SUSPENSE'}>Fiction: Suspense</MenuItem>
                                <MenuItem value={'FICTION_THRILLERS_GENERAL'}>Fiction: Thrillers (General)</MenuItem>
                                <MenuItem value={'FICTION_VISIONARY AND METAPHYSICAL'}>Fiction: Visionary and Metaphysical</MenuItem>
                                <MenuItem value={'FICTION_WAR AND MILITARY'}>Fiction: War and Military</MenuItem>
                                <MenuItem value={'FICTION_WESTERNS'}>Fiction: Westerns</MenuItem>
                                <MenuItem value={'FINANCE_PERSONAL'}>Finance: Personal</MenuItem>
                                <MenuItem value={'GAMES'}>Games</MenuItem>
                                <MenuItem value={'GARDENING'}>Gardening</MenuItem>
                                <MenuItem value={'GENEALOGY'}>Genealogy</MenuItem>
                                <MenuItem value={'HANDICRAFT'}>Handicraft</MenuItem>
                                <MenuItem value={'HEALTH'}>Health</MenuItem>
                                <MenuItem value={'AUSTRALIA_HISTORY'}>History: Australia</MenuItem>
                                <MenuItem value={'CANADA_HISTORY'}>History: Canada</MenuItem>
                                <MenuItem value={'GREAT BRITAIN_HISTORY'}>History: Great Britain</MenuItem>
                                <MenuItem value={'NEW ZEALAND_HISTORY'}>History: New Zealand</MenuItem>
                                <MenuItem value={'UNITED STATES_HISTORY'}>History: United States</MenuItem>
                                <MenuItem value={'INTERIOR DECORATION'}>Interior Decoration</MenuItem>
                                <MenuItem value={'INTERNET'}>Internet</MenuItem>
                                <MenuItem value={'INTERPERSONAL RELATIONS'}>Interpersonal Relations</MenuItem>
                                <MenuItem value={'LANGUAGE AND LANGUAGES_STUDY AND TEACHING'}>Language and Languages: Study and Teaching</MenuItem>
                                <MenuItem value={'LANGUAGE ARTS'}>Language Arts</MenuItem>
                                <MenuItem value={'LAW'}>Law</MenuItem>
                                <MenuItem value={'LITERATURE_COLLECTIONS'}>Literature: Collections</MenuItem>
                                <MenuItem value={'LITERATURE_HISTORY AND CRITICISM'}>Literature: History and Criticism</MenuItem>
                                <MenuItem value={'MATHEMATICS'}>Mathematics</MenuItem>
                                <MenuItem value={'MEDICINE'}>Medicine</MenuItem>
                                <MenuItem value={'MILITARY ART AND SCIENCE'}>Military Art and Science</MenuItem>
                                <MenuItem value={'MIND AND BODY'}>Mind and Body</MenuItem>
                                <MenuItem value={'MUSIC'}>Music</MenuItem>
                                <MenuItem value={'NATURE'}>Nature</MenuItem>
                                <MenuItem value={'NUTRITION'}>Nutrition</MenuItem>
                                <MenuItem value={'PARENTING'}>Parenting</MenuItem>
                                <MenuItem value={'PERFORMING ARTS'}>Performing Arts</MenuItem>
                                <MenuItem value={'PETS'}>Pets</MenuItem>
                                <MenuItem value={'PHILOSOPHY'}>Philosophy</MenuItem>
                                <MenuItem value={'PHOTOGRAPHY'}>Photography</MenuItem>
                                <MenuItem value={'PHYSICAL FITNESS'}>Physical Fitness</MenuItem>
                                <MenuItem value={'PHYSICS'}>Physics</MenuItem>
                                <MenuItem value={'POETRY (POETIC WORKS BY ONE AUTHOR)'}>Poetry (Poetic works by one author)</MenuItem>
                                <MenuItem value={'POETRY_COLLECTIONS'}>Poetry: Collections</MenuItem>
                                <MenuItem value={'POETRY_HISTORY AND CRITICISM'}>Poetry: History and Criticism</MenuItem>
                                <MenuItem value={'POLITICAL SCIENCE'}>Political Science</MenuItem>
                                <MenuItem value={'PSYCHOLOGY'}>Psychology</MenuItem>
                                <MenuItem value={'REFERENCE BOOKS'}>Reference Books</MenuItem>
                                <MenuItem value={'RELIGION'}>Religion</MenuItem>
                                <MenuItem value={'SCIENCE'}>Science</MenuItem>
                                <MenuItem value={'SELF-HELP TECHNIQUES'}>Self-Help Techniques</MenuItem>
                                <MenuItem value={'SOCIAL SCIENCES'}>Social Sciences</MenuItem>
                                <MenuItem value={'SPIRITUALITY'}>Spirituality</MenuItem>
                                <MenuItem value={'SPORTS'}>Sports</MenuItem>
                                <MenuItem value={'TECHNOLOGY'}>Technology</MenuItem>
                                <MenuItem value={'TRANSPORTATION'}>Transportation</MenuItem>
                                <MenuItem value={'TRAVEL'}>Travel</MenuItem>
                                <MenuItem value={'WIT AND HUMOR'}>Wit and Humor</MenuItem>
                                <MenuItem value={'WORLD HISTORY'}>World History</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>

                    <Col xs="12" sm="5">
                        <FormControl style={{ "width": "80%" }}>
                            <InputLabel shrink id="secondGenreLabel">
                            {this.state.secondGenreInput && this.state.secondGenreInput.length > 0 ? "Second Genre" : "" }
                            </InputLabel>
                            <Select
                                labelId="secondGenreLabel"
                                id="secondGenre"
                                value={this.state.secondGenreInput}
                                onChange={this.secondGenreValidation.bind(this)}
                                displayEmpty
                                autoWidth
                            >
                                <MenuItem value="" disabled={!(this.state.secondGenreInput && this.state.secondGenreInput.length > 0)}>{this.state.secondGenreInput && this.state.secondGenreInput.length > 0 ? "None" : "Second Genre" }</MenuItem>
                                <MenuItem value={'AGRICULTURE'}>Agriculture</MenuItem>
                                <MenuItem value={'ARCHITECTURE'}>Architecture</MenuItem>
                                <MenuItem value={'ART'}>Art</MenuItem>
                                <MenuItem value={'ASTROLOGY'}>Astrology</MenuItem>
                                <MenuItem value={'BIBLE_COMMENTARIES'}>Bible Commentaries</MenuItem>
                                <MenuItem value={'BIOGRAPHY'}>Biography</MenuItem>
                                <MenuItem value={'BUSINESS'}>Business</MenuItem>
                                <MenuItem value={"CHILDREN'S FICTION"}>Children's Fiction</MenuItem>
                                <MenuItem value={'COLLECTORS AND COLLECTING'}>Collectors and Collecting</MenuItem>
                                <MenuItem value={'COMICS AND GRAPHIC NOVELS_GENERAL'}>Comics and Graphic Novels (General)</MenuItem>
                                <MenuItem value={'COMPUTER SOFTWARE'}>Computer Software</MenuItem>
                                <MenuItem value={'COMPUTERS'}>Computers</MenuItem>
                                <MenuItem value={'COOKING'}>Cooking</MenuItem>
                                <MenuItem value={'CRIME'}>Crime</MenuItem>
                                <MenuItem value={'CURIOSITIES AND WONDERS'}>Curiosities and Wonders</MenuItem>
                                <MenuItem value={'DRAMA (DRAMATIC WORKS BY ONE AUTHOR)'}>Drama (Dramatic works by one author)</MenuItem>
                                <MenuItem value={'DRAMA_COLLECTIONS'}>Drama: Collections</MenuItem>
                                <MenuItem value={'DRAMA_HISTORY AND CRITICISM'}>Drama: History and Criticism</MenuItem>
                                <MenuItem value={'ECONOMICS'}>Economics</MenuItem>
                                <MenuItem value={'EDUCATION'}>Education</MenuItem>
                                <MenuItem value={'FAMILY'}>Family</MenuItem>
                                <MenuItem value={'FICTION_ACTION AND ADVENTURE'}>Fiction: Action and Adventure</MenuItem>
                                <MenuItem value={'FICTION_EROTICA'}>Fiction: Erotica</MenuItem>
                                <MenuItem value={'FICTION_ESPIONAGE'}>Fiction: Espionage</MenuItem>
                                <MenuItem value={'FICTION_FANTASY_GENERAL'}>Fiction: Fantasy (General)</MenuItem>
                                <MenuItem value={'FICTION_GAY'}>Fiction: Gay</MenuItem>
                                <MenuItem value={'FICTION_GENERAL'}>Fiction: General</MenuItem>
                                <MenuItem value={'FICTION_HISTORICAL'}>Fiction: Historical</MenuItem>
                                <MenuItem value={'FICTION_HORROR'}>Fiction: Horror</MenuItem>
                                <MenuItem value={'FICTION_MYSTERY AND DETECTIVE_GENERAL'}>Fiction: Mystery and Detective</MenuItem>
                                <MenuItem value={'FICTION_PSYCHOLOGICAL'}>Fiction: Psychological</MenuItem>
                                <MenuItem value={'FICTION_RELIGIOUS'}>Fiction: Religious</MenuItem>
                                <MenuItem value={'FICTION_ROMANCE_GENERAL'}>Fiction: Romance (General)</MenuItem>
                                <MenuItem value={'FICTION_SCIENCE FICTION_GENERAL'}>Fiction: Science Fiction (General)</MenuItem>
                                <MenuItem value={'FICTION_SHORT STORIES (SINGLE AUTHOR)'}>Fiction: Short Stories (Single author)</MenuItem>
                                <MenuItem value={'FICTION_SUSPENSE'}>Fiction: Suspense</MenuItem>
                                <MenuItem value={'FICTION_THRILLERS_GENERAL'}>Fiction: Thrillers (General)</MenuItem>
                                <MenuItem value={'FICTION_VISIONARY AND METAPHYSICAL'}>Fiction: Visionary and Metaphysical</MenuItem>
                                <MenuItem value={'FICTION_WAR AND MILITARY'}>Fiction: War and Military</MenuItem>
                                <MenuItem value={'FICTION_WESTERNS'}>Fiction: Westerns</MenuItem>
                                <MenuItem value={'FINANCE_PERSONAL'}>Finance: Personal</MenuItem>
                                <MenuItem value={'GAMES'}>Games</MenuItem>
                                <MenuItem value={'GARDENING'}>Gardening</MenuItem>
                                <MenuItem value={'GENEALOGY'}>Genealogy</MenuItem>
                                <MenuItem value={'HANDICRAFT'}>Handicraft</MenuItem>
                                <MenuItem value={'HEALTH'}>Health</MenuItem>
                                <MenuItem value={'AUSTRALIA_HISTORY'}>History: Australia</MenuItem>
                                <MenuItem value={'CANADA_HISTORY'}>History: Canada</MenuItem>
                                <MenuItem value={'GREAT BRITAIN_HISTORY'}>History: Great Britain</MenuItem>
                                <MenuItem value={'NEW ZEALAND_HISTORY'}>History: New Zealand</MenuItem>
                                <MenuItem value={'UNITED STATES_HISTORY'}>History: United States</MenuItem>
                                <MenuItem value={'INTERIOR DECORATION'}>Interior Decoration</MenuItem>
                                <MenuItem value={'INTERNET'}>Internet</MenuItem>
                                <MenuItem value={'INTERPERSONAL RELATIONS'}>Interpersonal Relations</MenuItem>
                                <MenuItem value={'LANGUAGE AND LANGUAGES_STUDY AND TEACHING'}>Language and Languages: Study and Teaching</MenuItem>
                                <MenuItem value={'LANGUAGE ARTS'}>Language Arts</MenuItem>
                                <MenuItem value={'LAW'}>Law</MenuItem>
                                <MenuItem value={'LITERATURE_COLLECTIONS'}>Literature: Collections</MenuItem>
                                <MenuItem value={'LITERATURE_HISTORY AND CRITICISM'}>Literature: History and Criticism</MenuItem>
                                <MenuItem value={'MATHEMATICS'}>Mathematics</MenuItem>
                                <MenuItem value={'MEDICINE'}>Medicine</MenuItem>
                                <MenuItem value={'MILITARY ART AND SCIENCE'}>Military Art and Science</MenuItem>
                                <MenuItem value={'MIND AND BODY'}>Mind and Body</MenuItem>
                                <MenuItem value={'MUSIC'}>Music</MenuItem>
                                <MenuItem value={'NATURE'}>Nature</MenuItem>
                                <MenuItem value={'NUTRITION'}>Nutrition</MenuItem>
                                <MenuItem value={'PARENTING'}>Parenting</MenuItem>
                                <MenuItem value={'PERFORMING ARTS'}>Performing Arts</MenuItem>
                                <MenuItem value={'PETS'}>Pets</MenuItem>
                                <MenuItem value={'PHILOSOPHY'}>Philosophy</MenuItem>
                                <MenuItem value={'PHOTOGRAPHY'}>Photography</MenuItem>
                                <MenuItem value={'PHYSICAL FITNESS'}>Physical Fitness</MenuItem>
                                <MenuItem value={'PHYSICS'}>Physics</MenuItem>
                                <MenuItem value={'POETRY (POETIC WORKS BY ONE AUTHOR)'}>Poetry (Poetic works by one author)</MenuItem>
                                <MenuItem value={'POETRY_COLLECTIONS'}>Poetry: Collections</MenuItem>
                                <MenuItem value={'POETRY_HISTORY AND CRITICISM'}>Poetry: History and Criticism</MenuItem>
                                <MenuItem value={'POLITICAL SCIENCE'}>Political Science</MenuItem>
                                <MenuItem value={'PSYCHOLOGY'}>Psychology</MenuItem>
                                <MenuItem value={'REFERENCE BOOKS'}>Reference Books</MenuItem>
                                <MenuItem value={'RELIGION'}>Religion</MenuItem>
                                <MenuItem value={'SCIENCE'}>Science</MenuItem>
                                <MenuItem value={'SELF-HELP TECHNIQUES'}>Self-Help Techniques</MenuItem>
                                <MenuItem value={'SOCIAL SCIENCES'}>Social Sciences</MenuItem>
                                <MenuItem value={'SPIRITUALITY'}>Spirituality</MenuItem>
                                <MenuItem value={'SPORTS'}>Sports</MenuItem>
                                <MenuItem value={'TECHNOLOGY'}>Technology</MenuItem>
                                <MenuItem value={'TRANSPORTATION'}>Transportation</MenuItem>
                                <MenuItem value={'TRAVEL'}>Travel</MenuItem>
                                <MenuItem value={'WIT AND HUMOR'}>Wit and Humor</MenuItem>
                                <MenuItem value={'WORLD HISTORY'}>World History</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col sm="2"/>
                </Row>

                <Row>
                    <Col xs="12" sm="7">
                        <FormControl style={{ "width": "80%" }}>
                            <InputLabel shrink id="targetAudienceLabel">
                                {this.state.targetAudienceInput ? "Target Audience*" : "" }
                            </InputLabel>
                            <Select
                                labelId="targetAudienceLabel"
                                id="targetAudience"
                                value={this.state.targetAudienceInput}
                                onChange={this.targetAudienceValidation.bind(this)}
                                displayEmpty
                                autoWidth
                            >
                                <MenuItem value="" disabled>Target Audience *</MenuItem>
                                <MenuItem value={'TRADE'}>General Adult</MenuItem>
                                <MenuItem value={'FAMILY'}>Family</MenuItem>
                                <MenuItem value={'YA'}>Young Adult</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                </Row>

                <Row style={{ "marginTop": "15px" }}>
                    <Col xs="9">
                        <InputLabel shrink id="targetAudienceLabel">
                        {(this.state.fileSubmissions && 1 === this.state.fileSubmissions.length) &&
                            "Your first 5 pages (.pdf .doc .docx) *"
                        }
                        </InputLabel>

                        {(this.state.fileSubmissions) &&
                            this.state.fileSubmissions.map(fileSubmission => (
                                <div>
                                    {fileSubmission.name}
                                    <Button color="inherit" onClick={this.removeFile.bind(this)}>
                                        <FontAwesomeIcon icon={faTrash} size="sm" color="#005fcc" style={{ "marginLeft": "8px" }}/>
                                    </Button>
                                </div>
                            ))
                        }
                        <div style={{ "color": (this.state.fileInputError || this.state.fileInputDirty) ? "red" : "#737373" }}>
                            {(!this.state.fileSubmissions || this.state.fileSubmissions.length === 0) &&
                                "Your first 5 pages (.pdf .doc .docx) *"
                            }
                        </div>
                        <Dropzone onDrop={this.fileValidation.bind(this)}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    <p>Drag file here or click to browse</p>
                                </div>
                            )}
                        </Dropzone>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12">
                        <TextareaAutosize required id="query" aria-label="minimum height" rowsMin={7} placeholder="Query *" style={{ "width": "80%", "marginTop": "15px" }} onChange={this.handleInput.bind(this)} onBlur={this.handleBlur.bind(this)} helperText={this.state.queryInputErrorText} error={this.state.queryInputError} maxLength="2000"/>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12">
                        <TextareaAutosize required id="bio" aria-label="minimum height" rowsMin={3} placeholder="About You *" style={{ "width": "80%", "marginTop": "15px" }} onChange={this.handleInput.bind(this)} onBlur={this.handleBlur.bind(this)} helperText={this.state.bioInputErrorText} error={this.state.bioInputError} maxLength="2000"/>
                    </Col>
                </Row>

                <Row>
                    <Recaptcha
                        onloadCallback={this.onCaptchaLoad}
                        sitekey={this.props.recaptchaSiteKey}
                        render="explicit"
                        verifyCallback={this.onCaptchaVerify}
                        theme="white"
                    />
                </Row>
                <Row>
                    <Button variant="contained" color="primary" onClick={() => {this.handleFormSubmit()}} disabled={!this.isValid()}>Submit</Button>
                </Row>

            </Container>
    	);
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		},
        handleProgressOpen: () => {
            dispatch(handleProgressOpen());
		},
        handleProgressClose: () => {
            dispatch(handleProgressClose());
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
        recaptchaSiteKey: state.site.recaptchaSiteKey
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionForm);
