import axios from 'axios';
import { serializeData } from '../forms';

const encodeCredentials = (credentials) => {
    let userPass = credentials.email + ':' + credentials.password;
    /*if (credentials.firstName && credentials.lastName) {
        userPass += ':' + credentials.firstName + ':' + credentials.lastName;
    }*/

    let octetString = userPass;
    let basicAuthValue = 'Basic ' + new Buffer(octetString).toString('base64');
    return basicAuthValue;
};

export async function register(credentials) {
    let basicAuthValue = encodeCredentials(credentials);
    let formBody = serializeData(credentials, 'firstName', 'lastName');

    const res = await axios.post('https://newfangle.press/rest/register',
        formBody,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': basicAuthValue
            }
        }
    );

    console.log(res);
}

export function getAccessToken() {
    return sessionStorage.getItem('token')
}

export async function login(credentials) {
    var loginAttempt = null;
    let basicAuthValue = encodeCredentials(credentials);

    try {
        const res = await axios.post('https://fandom.game/rest/auth',
            //{ example: 'data' },
            null, // empty body
            {
                //headers: { 'Content-Type': 'application/json' }
                headers: { 'Authorization': basicAuthValue }
            }
        );

        let start = res.data.token.indexOf('.') + 1;
        let end = res.data.token.indexOf('.', start);
        let payload = res.data.token.substring(start, end);
        let accountDataString = new Buffer(payload, 'base64').toString('ascii');

        loginAttempt = {
            account: JSON.parse(accountDataString)
        }

        sessionStorage.setItem('token', res.data.token);
        sessionStorage.setItem('login', loginAttempt.account.login);
    }
    catch (err) {
        console.error(err);
        loginAttempt = {
            error: err
        }
    }

    return loginAttempt;
}
