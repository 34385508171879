import React, { Component } from 'react'
import Recaptcha from 'react-gcaptcha'
import './ContactForm.css'
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { connect } from 'react-redux';
import { handleAlertOpen } from '../redux/actions/alertActions';

class ContactForm extends Component {
	constructor(props) {
		super(props);

		this.onCaptchaLoad = this.onCaptchaLoad.bind(this);
		window.onloadCallback = this.onCaptchaLoad;

		this.onCaptchaVerify = this.onCaptchaVerify.bind(this);
		window.verifyCallback = this.onCaptchaVerify;

        this.handleAlertOpen = this.props.handleAlertOpen.bind(this);

		this.state = {
			inputEmail: '',
		    inputMessage: '',
		    inputName: '',
            emailInputDirty: false,
            messageInputDirty: false,
            nameInputDirty: false,
		    isCaptchaValid: false
		};
	}

	componentDidMount() {
		const script = document.createElement("script");
	    script.src = "https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit";
	    script.async = true;
	    script.onload = () => this.scriptLoaded();
	    document.head.appendChild(script);
	}

	scriptLoaded() {
	}

	onCaptchaLoad() {
	}

    handleBlur(event) {
        this.setState({
            [event.target.id + 'InputDirty']: true
        });

        if (event.target.id === 'name') {
            this.nameValidation(event);
        }
        else if (event.target.id === 'email') {
            this.emailValidation(event);
        }
        else if (event.target.id === 'message') {
            this.messageValidation(event);
        }
    }

    handleInput(event) {
        this.setState({
            [event.target.id + 'InputDirty']: true
        });

        if (event.target.id === 'name') {
            this.nameValidation(event);
        }
        else if (event.target.id === 'email') {
            this.emailValidation(event);
        }
        else if (event.target.id === 'message') {
            this.messageValidation(event);
        }
    }

    nameValidation(event) {
        if (event.target.value.length === 0) {
            this.setState({
                inputNameError: true
            });
        }
        else {
            this.setState({
                inputName: event.target.value,
                inputNameError: false
            });
        }
    }

    messageValidation(event) {
        if (event.target.value.length === 0) {
            this.setState({
                inputMessageError: true
            });
        }
        else {
            this.setState({
                inputMessage: event.target.value,
                inputMessageError: false
            });
        }
    }

	emailValidation(event) {
        // eslint-disable-next-line
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (event.target.value.length === 0) {
            this.setState({
                inputEmailErrorText: null,
                inputEmailError: true
            });
        }
        else if (reg.test(String(event.target.value).toLowerCase())) {
            this.setState({
                inputEmail: event.target.value,
                inputEmailErrorText: null,
                inputEmailError: false
            });
        }
        else {
            this.setState({
                inputEmailErrorText: 'Invalid email address',
                inputEmailError: true
            });
        }
	}

	// Update state after reCaptcha validates visitor
	onCaptchaVerify(response) {
		this.setState({
			isCaptchaValid: true
		})
	}

    handleFormSubmit = event => {
        if (this.state.inputEmail.length > 0 && this.state.inputName.length > 0 && this.state.inputMessage.length > 0 && this.state.isCaptchaValid) {
            const res = axios.post(
                '/rest/connect/email',
                {
                    name: this.state.inputName,
                    email: this.state.inputEmail,
                    message: this.state.inputMessage
                },
                {
                    //headers: { 'Content-Type': 'application/json' }
                }
            ).then(console.log(res));

            // Reset state after sending the form
            this.setState({
                inputEmail: '',
    		    inputMessage: '',
    		    inputName: '',
                emailInputDirty: false,
                messageInputDirty: false,
                nameInputDirty: false,
    		    isCaptchaValid: false
            });

            this.handleAlertOpen({ title: 'Success', severity: 'success', message: 'Your message has been delivered. Newfangle Press will respond to you soon.' });
        }
    }

	render() {
    	return (
            <div>
                <div>
                    <TextField required id="name" label="Your Name" style={{ "width": "80%" }} onChange={this.handleInput.bind(this)} onBlur={this.handleBlur.bind(this)} helperText={this.state.inputNameErrorText} error={this.state.inputNameError}/>
                </div>
                <div>
                    <TextField required id="email" label="Your Email" style={{ "width": "80%" }} type="email" onChange={this.handleInput.bind(this)} onBlur={this.handleBlur.bind(this)} helperText={this.state.inputEmailErrorText} error={this.state.inputEmailError}/>
                </div>
                <div>
                    <TextareaAutosize required id="message" aria-label="minimum height" rowsMin={3} placeholder="Message" style={{ "width": "80%", "marginTop": "15px" }} onChange={this.handleInput.bind(this)} onBlur={this.handleBlur.bind(this)} helperText={this.state.inputMessageErrorText} error={this.state.inputMessageError}/>
                </div>
                <div>
                    <Recaptcha
                        onloadCallback={this.onCaptchaLoad}
                        sitekey={this.props.recaptchaSiteKey}
                        render="explicit"
                        verifyCallback={this.onCaptchaVerify}
                        theme="white"
                    />
                </div>
                <div>
                    <Button variant="contained" color="primary" onClick={() => {this.handleFormSubmit()}} disabled={!(this.state.inputEmail.length > 0 && this.state.inputName.length > 0 && this.state.inputMessage.length > 0 && this.state.isCaptchaValid)}>Send</Button>
                </div>
            </div>
    	);
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleAlertOpen: (alert) => {
            dispatch(handleAlertOpen(alert));
		}
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
		recaptchaSiteKey: state.site.recaptchaSiteKey
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
