// Action Types

export const OPEN_ALERT = 'OPEN_ALERT'
export const CLOSE_ALERT = 'CLOSE_ALERT'
export const OPEN_PROGRESS = 'OPEN_PROGRESS'
export const CLOSE_PROGRESS = 'CLOSE_PROGRESS'

// Other Constants


// Action creators

export function handleAlertClose() {
    return { type: CLOSE_ALERT }
}

export function handleAlertOpen(alert) {
    return { type: OPEN_ALERT, payload: { isAlertOpen: true, title: alert.title, message: alert.message, severity: alert.severity } }
}

export function handleProgressClose() {
    return { type: CLOSE_PROGRESS }
}

export function handleProgressOpen() {
    return { type: OPEN_PROGRESS }
}
